import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/convenience-and-community-in-the-heart-of-san-diego"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Convenience and Community in the Heart of San Diego.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Are you thinking about buying a home in Southern California? Then you'll want to
                have a pulse on what's happening in Clairemont, a deceptively sleepy little
                neighborhood 10 miles north of downtown San Diego. Here’s why we love Clairemont and
                why more and more of our clients have their eyes on it.
              </CommonParagraph>
              <Heading type="h2">Market Overview</Heading>
              <CommonParagraph>
                If you’re new to the area, Clairemont (officially Clairemont Mesa) is divided into
                five neighborhoods. Here's a brief market overview for each, using the most recent
                data available when publishing this article. The average median home prices and
                their changes since last year are as follows:
              </CommonParagraph>
              <CommonParagraph>
                <ul>
                  <li>Bay Park: $1.4 million, up 11.9%</li>
                  <li>Clairemont: $1 million, up 11.7%</li>
                  <li>North Clairemont: $1 million, up 7.3%</li>
                  <li>Bay Ho: $1 million, down 0.3%</li>
                  <li>Linda Vista: $854,865, up 12.2%</li>
                </ul>
              </CommonParagraph>
              <CommonParagraph>
                Currently, 87 houses are on the market, which is 10 more than were available in
                April. As of May, 31 homes have been sold, with 22 being sold over the asking price.
                On average, homes sold within 12 days of listing.
              </CommonParagraph>
              <Heading type="h3">Diverse Demographics</Heading>
              <CommonParagraph>
                Clairemont's diverse age demographics make it a welcoming community for everyone.
                With a balanced mix of young professionals (19% aged 25-34), growing families (13%
                aged 35-44 and 13% aged 45-54), empty nesters (13% aged 55-64), and retirees (17%
                aged 65+), you'll find neighbors who share your interests and experiences, no matter
                your stage of life.
              </CommonParagraph>
              <CommonParagraph>
                This inclusive atmosphere fosters a strong sense of belonging and creates a vibrant,
                supportive community that feels like home, whether you're single, raising a family,
                or enjoying your golden years.
              </CommonParagraph>
              <Heading type="h3">Green Space & Nature</Heading>
              <CommonParagraph>
                Perhaps you’re looking for a shady space to stretch out with a good book. Or maybe
                you'd rather have a cookout, picnic, or play basketball. There's plenty of open
                space (and olive trees!) at Olive Grove Park.
              </CommonParagraph>
              <CommonParagraph>
                Marian Bear Memorial Park is another local favorite, offering 467 acres of dedicated
                natural parkland and three miles of flat trails. We should also mention Tecolote
                Canyon Natural Park, which features over six miles of trails to walk, run, and
                bike—plus a nature center that hosts plant and wildlife exhibits.
              </CommonParagraph>
              <Heading type="h3">Premier Location</Heading>
              <CommonParagraph>
                Another thing we love about Clairemont is that no matter which section of it you
                choose, you’re in an ideal location. Just a short 5-10 minute drive away, you’ll
                find the stunning beaches of La Jolla and the charming atmosphere of Pacific Beach.
                Mission Bay is also within easy reach.{" "}
              </CommonParagraph>
              <CommonParagraph>
                For those who commute frequently or enjoy life on the go, Clairemont’s strategic
                location puts San Diego’s best within reach. The neighborhood is surrounded by major
                freeways. Interstates 52 and 5 intersect at the northwest corner, and the 8 and 805
                intersect at the southwest corner, which means getting to the airport or downtown
                San Diego is a quick 10-minute drive.{" "}
              </CommonParagraph>
              <Heading type="h3">Convenient Shopping</Heading>
              <CommonParagraph>
                No matter which of Clairemont's five districts you call home, you'll find yourself
                minutes away from plenty of shopping. Whether you prefer boutique mom-and-pop shops,
                big-box grocery stores, trendy restaurants, or cozy coffee shops, everything you
                need is just outside your front door.
              </CommonParagraph>
              <Heading type="h3">Food Galore</Heading>
              <CommonParagraph>
                Food options are also plentiful in Clairemont. Here are a few of our favorites:
                Havana Grill offers awesome Cuban fare, The Butcher N Cheese is a craft burger and
                local beer joint, Barrio Donas offers Mexican-inspired donuts, and we should also
                mention Convoy Village, which is situated on the edge of Clairemont. Here, you'll
                find some of the best Asian cuisine in San Diego. The choices are endless whether
                you're looking for good Korean BBQ, fresh sushi, or ramen.
              </CommonParagraph>
              <Heading type="h2">Let Us Help You Find the Perfect Clairemont Home</Heading>
              <CommonParagraph>
                Whether you’re looking for a starter home, ready to upgrade your square footage to
                accommodate a family, or need a comfortable place to enjoy retirement, we can help
                you find it in Clairemont. As a local-first company with deep roots in San Diego and
                Southern California, we know our communities well—which means we can help you
                discover the ideal home that fits your unique needs and lifestyle.{" "}
                <ContactSlideoutLink text="Contact us today" /> to start your journey home!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
